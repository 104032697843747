import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import styled from "styled-components";
import Faq from "../components/Homepage/Faq";
import { ContactUs } from "../components/Homepage/ContactUs";
// import { HeroStyles } from "../components/Homepage/styles";
import NewsletterSection from "../components/Homepage/NewsletterSection";
// import { MouseScroll } from "../components/Ui/MouseScroll";

export const query = graphql`
  query FAQPage {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      faq {
        name
        description
        faqQuestionsAnswers {
          # _id
          answer
          question
        }
      }

      newsLetterTitle
      newsLetterBackground {
        ...SanityImage
        alt
      }

      promoImageWeb {
        ...SanityImage
        alt
      }

      contactUs {
        address
        email
        phoneOne
        contactPeople {
          name
          image {
            ...SanityImage
            alt
          }
        }
        hours {
          days
          hours
        }
        businessHoursDescription
      }
    }
  }
`;

const FaqStyles = styled.div`
  h1 {
    width: 100%;
    text-align: center;
  }
  /* margin-top: 7rem; */

  .faq {
    &__section {
      margin: 0;
      /* padding: 15rem 10% 10rem 10%; */
      &:nth-of-type(even) {
        background: #faf7f7;
      }
      &:nth-of-type(odd) {
        background: #fff;
      }
    }
  }
`;

const FAQPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout {...props}>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        {/* <HeroStyles className="height-80vh">
          {site.promoImageWeb && site.promoImageWeb.asset && (
            <Image {...site.promoImageWeb} alt={site.promoImageWeb.alt} />
          )}
          <h1 className="disappear-on-scroll">
            Checkout our Frequently Asked Questions
          </h1>
          <MouseScroll />
        </HeroStyles> */}

        <FaqStyles className="page-content">
          {site.faq.map((faq) => (
            <Faq
              path="/faq"
              styles={{ paddingTop: "6rem" }}
              key={faq.name}
              faq={faq}
            />
          ))}

          <NewsletterSection site={site} />
          <ContactUs contactUs={site.contactUs} />
        </FaqStyles>
      </Container>
    </Layout>
  );
};

export default FAQPage;
